/*
	Scout's body:  #8bbfcc
	Scout's scarf: #c28fd2
	Map light:     #f3df94
	Map dark:      #cfbe7d
	Trail light:   #86c9be
	Trail dark:    #33a3d4
*/

body {
	font-family: sans-serif;
}

html, body, #root, .App {
	display: flex;
	flex-direction: column;
	flex:1;
	flex-grow: 1;
}

html, body {
	min-height: 100%;
}

.App>div.container {
	padding: 1rem;
	background-color: #e4e4e4;
	flex-grow: 1;
	flex:1;
}

footer {
	display: flex;
	justify-content: space-evenly;
	background-color: #ccc;
	color: #222;
	padding: 0.5rem 0;
}

.jumbotron {
	text-align: center;
	padding: 0.5rem;
	padding-bottom: 1rem;
	margin-bottom: 0;
	background-color: #dcdcdc;
}

#logoutButton {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
}

.tourContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.tourTile {
	margin-bottom: 1rem;
	width: 13rem;
	margin: 0.25rem;
}

.tourTile .card-header {
	padding: .75rem;
	font-weight: bold;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	background-color: #8bbfcc;
	color: #333;
}

.tourTile .card {
	height: 27em;
	overflow: hidden;
	text-overflow: ellipsis;
	border-color: #999;
	color: #555;
}

.tourTile .card-body {
	padding: .75rem;
	overflow: hidden;
}

.tourTile img.preview {
	width: 100%;
	height: 10rem;
	object-fit: cover;
	text-align: center;
	display: inline-block;
	border-bottom: 1px solid #ccc;
	filter: grayscale(75%);
}

.tourTile:hover .card {
	border-color: #333;
	color: #111;
}

.tourTile:hover img.preview {
	filter: grayscale(0%);
}

.tour-tag {
	background-color: #c28fd2;
	color: #000;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	margin: 0.1rem;
	border-radius: 0.5rem;
	white-space: nowrap;
	font-size: 10pt;
}

nav a {
	padding-left: 0.5em;
	padding-right: 0.5em;
}

h2.loading{
	padding-top: 5rem;
	padding-bottom: 5rem;
	min-height: 75%;
}

.container.page .card-header {
	background-color: #8bbfcc;
	color: #333;
}

.container.page .card {
	margin-bottom: 1rem;
}

th {
	background-color: #c28fd2;
	color: #222;
}

td {
	color: #222;
}