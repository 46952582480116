.fabButton {
	position: fixed;
	bottom: 1em;
	right: 2em;
}

.fabButton .btn {
	font-weight: bold;
	font-size: 14pt;
	border-radius: 14pt;
	padding-left: .6em;
	padding-right: .6em;
}

.addTourButton:hover .fabHoverText::after {
	content: " Add tour";
}

.modal {
	padding-top: 20%;
	display: block;
	background: #3339;
}